<template>
  <b-modal id="change-state" v-model="global.payments.paymentsVisible" title="Состояние заказа"
           @show="onShow" @ok="onSubmit">
    <b-input-group :prepend="'Всего: ' + (global.orders.selectedOrder.total - global.orders.selectedOrder.bonusRemoved - global.orders.selectedOrder.temporaryBonusRemoved)">
      <b-form-input
          type="number"
          placeholder="Предоплата"
          v-model="prepaid"
          disabled
      ></b-form-input>
      <b-input-group-append>
        <b-button disabled variant="secondary" v-if="!isPaid">Предоплата</b-button>
        <b-button disabled variant="success" v-else>Оплачен</b-button>
      </b-input-group-append>
    </b-input-group>
    <div>
      <div class="mt-3 mb-1">
        <div
            v-if="global.payments.selectedOrderPayments !== undefined
          && global.payments.selectedOrderPayments.length > 0">
          Платежи по заказу
        </div>
        <div v-else class="font-weight-bold">Нет платежей по заказу</div>
      </div>
      <div v-for="(payment, idx) in global.payments.selectedOrderPayments" class="mt-1 mb-1">
        <b-input-group>
          <b-input-group-prepend>
            <template v-if="payment.status === 2">
              <b-dropdown disabled :text="getType(payment.type).name">

              </b-dropdown>

            </template>
            <template v-else>
              <b-dropdown :text="getType(payment.type).name">
                <b-dropdown-item @click="cancelPayment(payment.id)">Отменить</b-dropdown-item>
                <template v-if="payment.type === 5">
                  <b-dropdown-item @click="copyLink(payment.link)">Скопировать ссылку</b-dropdown-item>
                </template>
              </b-dropdown>
            </template>
          </b-input-group-prepend>
          <b-form-input disabled v-model="payment.amount"></b-form-input>
          <b-input-group-append>
            <b-button disabled :variant="getStatus(payment.status).variant">{{
                getStatus(payment.status).name
              }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <b-input-group class="mt-5">
        <b-input-group-prepend>
          <b-dropdown :text="getType(newPayment.type).name" :disabled="isPaid">
            <b-dropdown-item v-for="(option, idx) in paymentTypesArr"
                             @click="newPayment.type = parseInt(option.value)">
              {{ option.text }}
            </b-dropdown-item>
          </b-dropdown>
        </b-input-group-prepend>
        <b-form-input v-model="newPayment.amount" :disabled="isPaid"></b-form-input>
        <b-input-group-append>
          <b-button variant="primary" :disabled="isPaid" @click="pay()">Оплатить</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-modal>
</template>

<script>

import {store} from "@/store"
import {consts} from "@/consts";

export default {

  data() {
    return {
      prepaid: 0,
      global: store,
      paymentTypes: consts.payments.paymentTypes,
      paymentTypesArr: consts.payments.paymentTypesArr,
      paymentsStatuses: consts.payments.paymentsStatuses,
      newPayment: {
        type: 1,
        status: 0
      }

    }
  },

  computed: {
    isPaid() {
      return this.global.orders.selectedOrder.prepaid === this.global.orders.selectedOrder.total
    },
    leftToPay() {
      const order = store.orders.selectedOrder
      return order.total - order.prepaid - order.bonusRemoved - order.temporaryBonusRemoved
    }
  },

  methods: {
    getStatus(status) {
      return this.paymentsStatuses[status]
    },
    getType(type) {
      return this.paymentTypes[type]
    },

    onShow() {
      const order = store.orders.selectedOrder
      this.prepaid = order.prepaid
      this.newPayment = {
        type: 1,
        amount: this.leftToPay,
        status: 0
      }
    },
    async copyLink(url) {
      await navigator.clipboard.writeText(url);
      this.$bvToast.toast(`Ссылка на оплату скопирована`, {
        title: 'Оплата',
        autoHideDelay: 1000,
        appendToast: true,
        solid: true
      })
    },

    async cancelPayment(paymentId) {
      const res = await this.$api.postParams("payment/cancel", {
        paymentId: paymentId,
      });
      this.$bvToast.toast(`Оплата отменена`, {
        title: 'Оплата',
        autoHideDelay: 1000,
        appendToast: true,
        solid: true
      })
      store.payments.paymentsVisible = false
      store.payments.selectedOrderPayments = []

    },

    async pay() {
      let res = null;
      switch (this.newPayment.type) {
        case 5:
          res = await this.$api.postParams("payment/mixplat", {
            amount: this.newPayment.amount,
            orderId: this.global.orders.selectedOrder.id,
          });
          await this.copyLink(res.link)
          break
        default:
          res = await this.$api.postParams("payment/local", {
            amount: this.newPayment.amount,
            orderId: this.global.orders.selectedOrder.id,
            paymentType: this.newPayment.type,
          });
          this.$bvToast.toast(`Оплата зарегистрирована`, {
            title: 'Оплата',
            autoHideDelay: 1000,
            appendToast: true,
            solid: true
          })
          break
      }
      // store.payments.selectedOrderPayments = await this.$api.get("payment/order", {
      //   orderId: this.global.orders.selectedOrder.id
      // })
      store.payments.paymentsVisible = false
      store.payments.selectedOrderPayments = []
    },

    async onSubmit(event) {
      event.preventDefault()
      store.payments.paymentsVisible = false
      store.payments.selectedOrderPayments = []
    }

  }
}
</script>
