<template>
  <main v-if="!isLoading">
    <b-navbar toggleable="sm" type="dark" variant="dark" sticky>
      <b-navbar-brand href="#">
        <!--        <b-img src="dosflores-logo.png" height="32" width="32"></b-img>-->
        Dos Flores
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/orders">Заказы</b-nav-item>
          <b-nav-item to="/customers">Клиенты</b-nav-item>
          <b-nav-item to="/products">Товары</b-nav-item>
<!--          <b-nav-item to="/periodic">Периодические заказы</b-nav-item>-->
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-text>{{ user.firstName }}</b-nav-text>
          <b-nav-item>
            <b-link href="#" @click="logout" class="text-secondary ml-2">Выйти</b-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-container fluid>
      <router-view></router-view>
    </b-container>

    <AddCustomer/>
    <AddTemporaryBonus/>
    <ChangeClientType/>
  </main>
</template>

<style>
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 64px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
</style>

<script>
import AddCustomer from "@/views/AddCustomer";
import ChangeClientType from "@/views/ChangeClientType";
import AddTemporaryBonus from "@/views/AddTemporaryBonus";
import {store} from "@/store";

export default {
  components: {AddCustomer, AddTemporaryBonus, ChangeClientType},

  data() {
    return {
      user: {},
      isLoading: true
    }
  },

  created() {
    this.fetchData()

    this.$api.connectWebSocket()

    this.wsListener = msg => {
      if (msg.event === 'logout') {
        this.$api.logout()
      }
    };
    this.$api.addWebSocketListener(this.wsListener)
  },

  beforeDestroy() {
    this.$api.removeWebSocketListener(this.wsListener)
    this.$api.closeWebSocket()
  },

  methods: {
    async fetchData() {
      this.isLoading = true

      let promise = this.$api.get("user/me")
      this.$api.promiseToWait = promise
      const res = await promise
      this.user = res
      store.user = res

      this.isLoading = false
    },

    logout(event) {
      event.preventDefault()
      this.$api.logout()
    },
  }
}
</script>
