<template>
  <b-modal id="add-order" size="md" v-model="global.addOrderVisible" scrollable
           :title="isNew ? 'Добавить заказ' : 'Редактировать заказ'"
           @show="onShow" @ok="onSubmit">
    <b-form @submit="onSubmit">
      <SelectCustomer :customers="customers" :order="order"
                    :server-validation-error="serverValidationError" :validate-customer="validateCustomer"/>

      <p>Товары:</p>
      <OrderItems :order="order" :products="products" :submitted="submitted" :product-search="productSearch"/>

      <b-form-row>
        <b-form-checkbox v-model="bridget" class="mb-2 ml-1">
          Букет невесты
        </b-form-checkbox>
      </b-form-row>

      <b-form-group>
        <b-form-textarea
            placeholder="Комментарий"
            v-model="order.comment"
            rows="4"
            max-rows="6"
            debounce="250">
        </b-form-textarea>
      </b-form-group>

      <b-form-group label="Доставка:">
        <b-form-radio-group
            v-model="order.deliveryType"
            :options="deliveryTypesArr"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-radio-group>
      </b-form-group>

      <b-form-row>
        <b-col>
          <b-form-group v-if="order.deliveryType === 4">
            <b-form-input
                type="number"
                placeholder="Доставка"
                v-model="deliveryCostCustom"
                debounce="250">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <p class="text-right mt-1">Доставка: {{ deliveryCost }}р.</p>
        </b-col>
      </b-form-row>

      <b-form-group>
        <b-form-datepicker
            v-model="order.deliveryDate"
            placeholder="Дата доставки"
            today-button
            reset-button
            close-button
            start-weekday="1"
            :min="deliveryMinDate"
            :value-as-date="true">
          >
        </b-form-datepicker>
      </b-form-group>

      <b-form-group>
        <b-form-radio-group
            v-model="order.deliveryIntervalType"
            :options="deliveryIntervalTypesArr"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-radio-group>
      </b-form-group>

      <b-form-row>
        <b-col>
          <b-form-group v-if="order.deliveryIntervalType !== 1" label="к:" label-cols-sm="2">
            <b-form-input
                placeholder="Время доставки"
                v-model="order.deliveryIntervalStart"
                :formatter="timeFormatter"
                :state="validateDeliveryIntervalStart">
            </b-form-input>
            <b-form-invalid-feedback :state="validateDeliveryIntervalStart">
              Введите время в формате 09:15
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group v-if="order.deliveryIntervalType === 1" label="c:" label-cols-sm="2">
            <b-form-input
                placeholder="Начало интервала"
                v-model="order.deliveryIntervalStart"
                :formatter="timeFormatter"
                :state="validateDeliveryIntervalStart">
            </b-form-input>
            <b-form-invalid-feedback :state="validateDeliveryIntervalStart">
              Введите время в формате 09:15
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group v-if="order.deliveryIntervalType === 1" label="до:" label-cols-sm="2">
            <b-form-input
                placeholder="Конец интервала"
                v-model="order.deliveryIntervalEnd"
                :formatter="timeFormatter"
                :state="validateDeliveryIntervalEnd">
            </b-form-input>
            <b-form-invalid-feedback :state="validateDeliveryIntervalEnd">
              Введите время в формате 09:15
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group v-if="order.deliveryType !== 3">
            <b-form-input
                type="text"
                placeholder="Имя получателя"
                v-model="order.deliveryContactName"
                debounce="250"
                :state="validateDeliveryContactName">
            </b-form-input>
            <b-form-invalid-feedback :state="validateDeliveryContactName">
              Введите имя получателя
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group v-if="order.deliveryType !== 3">
            <b-form-input
                type="text"
                placeholder="Телефон получателя"
                v-model="order.deliveryContact"
                debounce="250"
                :state="validateDeliveryContact">
            </b-form-input>
            <b-form-invalid-feedback :state="validateDeliveryContact">
              Введите номер телефона получателя в формате +7   123 4567890
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-group v-if="order.deliveryType !== 3">
        <vue-simple-suggest
            type="text"
            placeholder="Адрес получателя"
            v-model="order.deliveryAddress"
            :debounce="250"
            :state="validateDeliveryAddress"
            :list="addressSuggestionList"
            :filter-by-query="false"
        >
        </vue-simple-suggest>
        <b-form-invalid-feedback :state="validateDeliveryAddress">
          Введите адрес получателя
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-textarea
            placeholder="Комментарий для курьера"
            v-model="order.deliveryComment"
            rows="4"
            max-rows="6"
            debounce="250">
        </b-form-textarea>
      </b-form-group>
      <b-form-row>
        <b-col>
          <b-form-group label="Скидка %" label-cols-sm="6">
            <b-form-input
                type="number"
                v-model="order.discount"
                debounce="250"
                :state="validateDiscount">
            </b-form-input>
            <b-form-invalid-feedback :state="validateDiscount">
              Скидка должна быть от 0 до 50
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Скидка руб." label-cols-sm="6">
            <b-form-input
                type="number"
                v-model="order.discountFixed"
                debounce="250"
                :state="validateDiscountFixed">
            </b-form-input>
            <b-form-invalid-feedback :state="validateDiscountFixed">
              Скидка должна быть больше ноля
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-group label="Бонус" label-cols-sm="3">
        <b-form-radio-group
            v-model="bonusCase"
            :options="bonusCases"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-radio-group>
      </b-form-group>
      <b-form-row>
        <b-col>
          <p class="text-right mt-1">
            Всего:
            <del v-if="order.discount > 0 || order.discountFixed > 0 || this.bonusCase === 1"
                 class="text-secondary font-weight-light">{{ itemsCost }}
            </del>
            {{ itemsCostWithDiscount }}<span v-if="deliveryCost > 0" class="">+{{ deliveryCost }} = {{ total }}</span>р.
          </p>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="6">
          <b-form-group label="Предполата" label-cols-sm="6">
            <b-form-input
                type="number"
                v-model="order.prepaid"
                debounce="250">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <p class="text-right mt-1">Остаток: {{ leftToPay }}р.</p>
        </b-col>
      </b-form-row>

    </b-form>
  </b-modal>
</template>

<style scoped>
>>> {
  --vs-search-input-placeholder-color: lightgray;
}
</style>

<script>

import {isBlank, isTime} from "@/utils";
import {store} from "@/store"
import {consts} from "@/consts";
import OrderItems from "@/views/OrderItems";
import SelectCustomer from "@/views/SelectCustomer";
import VueSimpleSuggest from 'vue-simple-suggest/lib'

export default {
  components: {SelectCustomer, OrderItems, VueSimpleSuggest},
  data() {
    return {
      submitted: false,
      serverValidationError: null,
      order: this.newOrder(),
      bridget: false,
      customers: [],
      products: [],
      florists: [],
      global: store.orders,
      searchCustomer: "",
      deliveryMinDate: new Date(),
      deliveryCostCustom: 0,
      deliveryTypesArr: consts.orders.deliveryTypesArr,
      deliveryIntervalTypesArr: consts.orders.deliveryIntervalTypesArr,
      bonusCase: -1
    }
  },
  props: {
    productSearch: Function
  },

  computed: {
    itemsCost() {
      return this.order.items.reduce((sum, i) => sum + i.amount * i.price, 0)
    },
    bonusAdded() {
      return Math.round(this.itemsCost * 0.05);
    },
    itemsCostWithDiscount() {
      return this.itemsCost * (1 - (this.order.discount ? this.order.discount : 0) / 100)
          - (this.order.discountFixed ? this.order.discountFixed : 0)
          - (this.order.state === undefined ? (this.bonusCase === 1 ? (this.order.customer.bonus + this.order.customer.temporaryBonus) : 0)
          : (this.bonusCase === 1 ? (this.order.bonusRemoved + this.order.temporaryBonusRemoved) : 0))
    },
    deliveryCost() {
      //if (this.order.deliveryCost !== 0) return this.order.deliveryCost
      if (this.order.deliveryType === 3) return 0;
      if (this.order.deliveryType === 4) return Number(this.deliveryCostCustom);

      let d = 0
      if (this.order.deliveryType === 1) d = 450;
      if (this.order.deliveryType === 2) d = 350;
      if (this.order.deliveryIntervalType === 2) d += 300;

      return d;
    },
    bonusCases() {
      var b = []

      if (this.order.finalState) {
        b[0] = {value: -1, text: "Начислили " + this.order.bonusAdded, disabled: true}
        b[1] = {value: 1, text: "Списали " + (this.order.bonusRemoved + this.order.temporaryBonusRemoved), disabled: true}
      } else {
        b[0] = {value: -1, text: "Начислить " + this.bonusAdded}

        if (!this.order.customer || this.order.customer.bonus + this.order.customer.temporaryBonus <= 0) {
          b[1] = {value: 1, text: "Списать 0", disabled: true}
        } else {
          b[1] = {value: 1, text: "Списать " + (this.order.customer.bonus + this.order.customer.temporaryBonus)}
        }
      }

      return b;
    },
    total() {
      return this.itemsCostWithDiscount + this.deliveryCost;
    },
    leftToPay() {
      return this.total - (this.order.prepaid ? this.order.prepaid : 0)
    },
    validateCustomer() {
      return this.submitted ? (this.order.customer != null && this.serverValidationError == null) : null
    },
    validateDeliveryContact() {
      return this.submitted ? this.order.deliveryType === 3 || !isBlank(this.order.deliveryContact) : null
    },
    validateDeliveryContactName() {
      return this.submitted ? this.order.deliveryType === 3 || !isBlank(this.order.deliveryContactName) : null
    },
    validateDeliveryAddress() {
      return this.submitted ? this.order.deliveryType === 3 || !isBlank(this.order.deliveryAddress) : null
    },
    validateDeliveryIntervalStart() {
      return this.submitted ? isTime(this.order.deliveryIntervalStart) : null
    },
    validateDeliveryIntervalEnd() {
      return this.submitted ? this.order.deliveryIntervalType === 2 || isTime(this.order.deliveryIntervalEnd) : null
    },
    validateDiscount() {
      return this.submitted ? (this.order.discount >= 0 && this.order.discount <= 50) : null
    },
    validateDiscountFixed() {
      return this.submitted ? (this.order.discountFixed >= 0) : null
    },
    isNew() {
      return this.order.id == null
    }
  },

  methods: {
    newOrder() {
      return {
        deliveryType: 1,
        deliveryIntervalType: 1,
        items: [{amount: 1, price: 0, product: {id: 1, name: "Свободная позиция"}}],
        paymentType: 1,
        customer: null,
        discount: 0,
        discountFixed: 0,
        deliveryCost: 0,
        deliveryAddress: "",
        tags: "",
        bonusRemoved: 0,
        temporaryBonusRemoved: 0
      }
    },
    onProductSearch(search, loading) {
      loading(true);
      this.productSearch(loading, search, this);
    },
    addressSuggestionList() {
      return this.$api.get("delivery/search_address", {query: this.order.deliveryAddress})
    },
    onShow() {
      // clone order for editing
      this.order = Object.assign({}, this.newOrder(), this.global.selectedOrder)
      // copy items for editing
      this.order.items = this.order.items.map(a => ({...a}))
      this.bridget = this.order.tags !== null && this.order.tags !== ''
      this.deliveryCostCustom = this.order.deliveryCost
      this.submitted = false
      this.serverValidationError = null
      this.bonusCase = this.order.bonusRemoved + this.order.temporaryBonusRemoved > 0 ? 1 : -1

      this.$api.loadFlorists(res => {
        this.florists = res
      })
      this.onProductSearch("", () => {
      })
    },

    validateItemProduct(item) {
      return this.submitted ? item.product != null : null
    },

    validateItemAmount(item) {
      return this.submitted ? item.amount > 0 : null
    },

    validateItemPrice(item) {
      return this.submitted ? item.price >= 0 : null
    },

    validateItems() {
      return this.order.items.every(i => i.product != null && i.amount > 0 && i.price >= 0)
    },

    timeFormatter(time) {
      if (!time || time.length <= 0) return time

      if (time.length >= 3 && time.at(2) !== ":") {
        time = time.substring(0, 2) + ":" + time.substring(2)
      }

      if (time.length > 5) {
        time = time.substring(0, 5)
      }

      return time
    },

    onCustomerCreated(newCustomer) {
      this.order.customer = newCustomer
    },

    async onSubmit(event) {
      event.preventDefault()

      this.submitted = true
      this.serverValidationError = null

      if (this.validateCustomer && this.validateItems() && this.validateDeliveryContact &&
          this.validateDeliveryContactName && this.validateDeliveryAddress &&
          this.validateDiscount && this.validateDiscountFixed &&
          this.validateDeliveryIntervalStart && this.validateDeliveryIntervalEnd) {
        try {
          this.order.deliveryCost = this.deliveryCost
          this.order.total = this.total
          this.order.tags = (this.bridget) ? "1" : null

          this.order.createBy = store.user;

          if (!this.order.finalState) {
            if (this.bonusCase === -1) {
              this.order.temporaryBonusRemoved = 0
              this.order.bonusAdded = this.bonusAdded
              this.order.bonusRemoved = 0
            } else {
              this.order.temporaryBonusRemoved = this.order.customer.temporaryBonus
              this.order.bonusRemoved = this.order.customer.bonus
              this.order.bonusAdded = 0
            }
          }

          let newOrder = await this.$api.post("order", this.order)
          this.global.onOrderSaved(newOrder)
          this.global.addOrderVisible = false
          if (this.isNew) {
            this.$bvToast.toast(`${newOrder.id} добавлен`, {
              title: 'Новый заказ',
              autoHideDelay: 5000,
              appendToast: true,
              solid: true
            })
          }
        } catch (e) {
          this.serverValidationError = e.toString()
        }
      }
    }
  }
}
</script>
