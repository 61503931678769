<template>
  <b-modal id="add-order" size="md" v-model="global.addPeriodicOrderVisible" scrollable
           :title="'Добавить заказ через форму'"
           @show="onShow" @ok="onSubmit">
    <b-form @submit="onSubmit">
      <SelectCustomer :customers="customers" :order="order" :server-validation-error="serverValidationError"
                      :validate-customer="validateCustomer"></SelectCustomer>
      <b-form-input
          placeholder="Периодичность"
          v-model="order.cron">
      </b-form-input>
    </b-form>
  </b-modal>
</template>

<script>
import SelectCustomer from "@/views/SelectCustomer";
import {store} from "@/store"

export default {
  name: "AddOrderWithForm",
  components: {SelectCustomer},
  props: {
    onSave: Function
  },
  data() {
    return {
      submitted: false,
      serverValidationError: null,
      order: this.newOrder(),
      customers: [],
      global: store.periodicOrders,
    }
  },
  computed: {
    total() {
      return this.order.items.reduce((sum, i) => sum + i.amount * i.price, 0)
    },
  },
  methods: {
    validateCustomer() {
      return this.submitted ? (this.order.customer != null && this.serverValidationError == null) : null
    },
    newOrder() {
      return {
        customer: null,
        cron: ""
      }
    },
    onShow() {
      this.submitted = false
      this.serverValidationError = null
      this.order = Object.assign({}, this.global.selectedPeriodicOrder)
      console.log(this.order)
    },
    async onSubmit(event) {
      event.preventDefault()

      this.submitted = true
      this.serverValidationError = null

      if (this.validateCustomer()) {
        try {
          let newOrder = await this.$api.post("periodic", this.order)
          this.global.addPeriodicOrderVisible = false
          // if (this.isNew) {
          //   this.$bvToast.toast(`${newOrder.id} добавлен`, {
          //     title: 'Новый заказ',
          //     autoHideDelay: 5000,
          //     appendToast: true,
          //     solid: true
          //   })
          // }
          this.onSave()
        } catch (e) {
          this.serverValidationError = e.toString()
        }
      }
    },

  }
}
</script>

<style scoped>

</style>
