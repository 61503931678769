export const store = {
    user: {},

    products: {
        selectedProduct: {},
        addProductVisible: false
    },

    periodicOrders: {
        selectedPeriodicOrder: {},
        addPeriodicOrderVisible: false,
    },

    payments: {
        selectedOrderPayments: [],
        paymentsVisible: false,
    },

    customers: {
        selectedCustomer: {},
        addCustomerVisible: false,
        addTemporaryBonusVisible: false,
        changeCustomerTypeVisible: false,
        onCustomerSaved: function () {
        },
        viewCustomerVisible: false,

        showAddCustomer(editCustomer, onSaved) {
            if (editCustomer == null) editCustomer = {}
            store.customers.selectedCustomer = editCustomer
            if (onSaved) store.customers.onCustomerSaved = onSaved
            store.customers.addCustomerVisible = true
        },

        showChangeCustomerType(customer, onSaved) {
            store.customers.selectedCustomer = customer
            if (onSaved) store.customers.onCustomerSaved = onSaved
            store.customers.changeCustomerTypeVisible = true
        },

        showViewCustomer(viewCustomer, onSaved) {
            store.customers.selectedCustomer = viewCustomer
            if (onSaved) store.customers.onCustomerSaved = onSaved
            store.customers.viewCustomerVisible = true
        },

        showAddTemporaryBonus(onSaved) {
            if (onSaved) store.customers.onCustomerSaved = onSaved
            store.customers.addTemporaryBonusVisible = true
        },

    },

    orders: {
        selectedOrder: {},
        addOrderVisible: false,
        fastAddOrderVisible: false,
        changeStateVisible: false,
        changeCollectorVisible: false,
        linkDeliveryVisible: false,
        cancelAutoDeliveryVisible: false,
        addOrderWithFormVisible: false,
        changeCourierVisible: false,
        onOrderSaved: function () {
        },

        showAddOrder(selectedOrder, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.addOrderVisible = true
        },

        showAddOrderWithForm(selectedOrder, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.addOrderWithFormVisible = true
        },

        showFastAddOrder(onSaved) {
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.fastAddOrderVisible = true
        },

        showChangeState(selectedOrder, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.changeStateVisible = true
        },
        showPayments(selectedOrder, selectedOrderPayments, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            store.payments.selectedOrderPayments = selectedOrderPayments
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.payments.paymentsVisible = true
        },
        showChangeCollector(selectedOrder, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.changeCollectorVisible = true;
        },
        linkWithYandexGo(selectedOrder, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.linkDeliveryVisible = true
        },

        cancelAutoDelivery(selectedOrder, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.cancelAutoDeliveryVisible = true
        },

        changeCourier(selectedOrder, onSaved) {
            if (!selectedOrder) selectedOrder = {}
            store.orders.selectedOrder = selectedOrder
            if (onSaved) store.orders.onOrderSaved = onSaved
            store.orders.changeCourierVisible = true
        }

    }
}
