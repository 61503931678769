export const consts = {
    customers: {
        contactTypes: {
            1: {name: "WA"},
            2: {name: "Звонить"},
            3: {name: "Telega"},
            4: {name: "VK"},
            5: {name: "Insta"}
        },
        customerTypes: {
            0: {name: "Не указан"},
            1: {name: "Мужской"},
            2: {name: "Женский"},
            3: {name: "Юр лицо"}
        }
    },
    orders: {
        states: {
            0: {name: "Не подтвержден", description: "Необходимо связаться с заказчиков для подтверждения"},
            1: {name: "Принят", description: "Заказчик подтвердил заказ"},
            2: {name: "Отказ", description: "Заказчик не подтвердил заказ"},
            3: {name: "Готов", description: "Букет собран и готов к отправке"},
            5: {name: "Отправлен", description: "Заказ отправлен и ждем подтверждение получения"},
            4: {name: "Доставлен", description: "Заказ доставлен и принят или заказчик сам забрал"},
            6: {name: "Не доставлен", description: "Заказ не удалось доставить"},
            100: {name: "Импортирован", description: "Заказ импортирован из старой системы"},
        },
        paymentTypes: {
            1: {name: "Сбербанк"},
            2: {name: "ЮКасса"},
            3: {name: "Терминал"},
            4: {name: "Наличные"},
            5: {name: "Mixplat"},
        },
        deliveryTypes: {
            1: {name: "До двери"},
            2: {name: "До дома"},
            3: {name: "Самовывоз"},
            4: {name: "Далеко"}
        },
        deliveryIntervalTypes: {
            1: {name: "Интервал"},
            2: {name: "Точно ко времени"}
        },
        couriers: {
            0: "Не назначена",
            1: "Яндекс",
            2: "Драйви",
            3: "Валерий",
            4: "Рошад",
            5: "Дониёр",
            6: "Санжар",
            7: "Баха",
            8: "Паша",
            9: "Эрик",
            10: "Федя",
            11: "Матвей",
            12: "Алихан",
            13: "Станислав",
            14: "Анастасия",
            15: "Игорь"
        },
    },
    payments : {
        paymentTypes: {
            1: {name: "Сбербанк"},
            2: {name: "ЮКасса"},
            3: {name: "Терминал"},
            4: {name: "Наличные"},
            5: {name: "Mixplat"},
        },
        paymentsStatuses: {
            0: {name: "Ожидается", variant: "secondary"},
            1: {name: "Успешно", variant: "success"},
            2: {name: "Ошибка", variant: "danger"},
        }
    }
}

function objectToArray(o) {
    return Object.keys(o).map(key => {
        return {value: Number(key), text: o[key].name}
    })
}

// convert object 0:{name:xx} to arrays [{value:0,text:xx}]
consts.orders.deliveryTypesArr = objectToArray(consts.orders.deliveryTypes)
consts.orders.deliveryIntervalTypesArr= objectToArray(consts.orders.deliveryIntervalTypes)
consts.orders.paymentTypesArr = objectToArray(consts.orders.paymentTypes)
consts.orders.statesArr = objectToArray(consts.orders.states)
consts.orders.statesWithoutImportedArr = objectToArray(consts.orders.states).filter(s => s.value !== 100)
consts.orders.couriersArr = Object.entries(consts.orders.couriers).map(([k, v]) => ({value: k, label: v}))

consts.customers.contactTypesArr = objectToArray(consts.customers.contactTypes)
consts.customers.customerTypesArr = objectToArray(consts.customers.customerTypes)

consts.payments.paymentTypesArr = objectToArray(consts.payments.paymentTypes)
consts.payments.paymentsStatusesArr = objectToArray(consts.payments.paymentsStatuses)
