<template>
  <b-modal id="change-collector" v-model="global.changeCollectorVisible" title="Сборщик заказа"
           @show="onShow" @ok="onSubmit" size="sm">

    <b-container fluid>
      <b-form-row align-h="center">
        <b-col>
          <b-form-group>
            <b-form-radio-group v-model="newCollector" :options="florists" button-variant="outline-primary" buttons
                                stacked>
            </b-form-radio-group>
            <b-form-invalid-feedback :state="validate">
              {{ serverValidationError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-container>

  </b-modal>
</template>

<script>
import {store} from "@/store";

export default {
  data() {
    return {
      newCollector: null,
      serverValidationError: null,
      submitted: false,
      global: store.orders,
      florists: [],
      floristsRaw: []
    }
  },

  computed: {
    validate() {
      return this.submitted ? this.serverValidationError == null : null
    }
  },

  methods: {
    onShow() {
      this.newCollector = this.global.selectedOrder.collectBy ? this.global.selectedOrder.collectBy.phone : null

      this.$api.loadFlorists(res => {
        this.floristsRaw = res
        this.florists = res.map(f => ({
            text: f.firstName + (f.lastName ? " " + f.lastName : ""),
            value: f.phone
        }))
      })
    },

    async onSubmit(event) {
      event.preventDefault()
      this.submitted = true
      this.global.selectedOrder.collectBy = this.floristsRaw.find(florist => florist.phone === this.newCollector)
      try {
        await this.$api.post("order", this.global.selectedOrder)
        this.global.onOrderSaved()
        this.global.changeCollectorVisible = false
      } catch (e) {
        this.serverValidationError = e.toString()
      }
    }
  }
}
</script>
