import Vue from 'vue'
import Router from 'vue-router'
import Main from "@/views/Main";
import Orders from "@/views/Orders";
import Products from "@/views/Products";
import Customers from "@/views/Customers";
import OrderForm from "@/views/OrderForm";
import PeriodicOrders from "@/views/PeriodicOrders";

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/',
      redirect: '/orders'
    },
    {
      path: '/orders',
      component: Main,
      children: [{
        path: '/orders',
        component: Orders,
        props: (route) => ({
          tab: route.query.tab ? parseInt(route.query.tab) : 1,
          customerId: parseInt(route.query.customerId)
        })
      }]
    },
    {
      path: '/products',
      component: Main,
      children: [{
        path: '/products',
        component: Products
      }]
    },
    {
      path: '/customers',
      component: Main,
      children: [{
        path: '/customers',
        component: Customers
      }]
    },
    {
      path: '/new_order',
      component: OrderForm
    },
    {
      path: '/periodic',
      component: Main,
      children: [{
        path: '/periodic',
        component: PeriodicOrders
      }]
    },
  ]
})
