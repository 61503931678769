<template>
  <b-modal id="change-delivery" v-model="global.linkDeliveryVisible" title="Доставка"
           @show="onShow" @ok="onSubmit">
    <b-form-group>
      <b-form-radio-group v-if="claims.length > 0" :options="claims" v-model="selectedClaimId"
                          text-field="addressShortName" value-field="claimId" button-variant="outline-primary" buttons
                          stacked>
      </b-form-radio-group>
      <p v-else>Нет активных доставок</p>
    </b-form-group>
  </b-modal>
</template>


<script>

import {store} from "@/store"

export default {

  data() {
    return {
      selectedClaimId: null,
      claims: [],
      global: store.orders,
    }
  },

  methods: {
    loadActiveDeliveries() {
      this.$api.get("delivery/active").then(value => {
        this.claims = value
        console.log(this.claims)
      })
    },
    onShow() {
      this.loadActiveDeliveries()
      if (this.selectedClaimId === null && store.orders.selectedOrder.deliveryClaimId !== null) {
        this.selectedClaimId = store.orders.selectedOrder.deliveryClaimId
      } else if (store.orders.selectedOrder.deliveryClaimId === null) {
        this.selectedClaimId = null;
      }
    },

    async onSubmit(event) {
      event.preventDefault()
      store.orders.selectedOrder.deliveryClaimId = this.selectedClaimId
      try {
        await this.$api.post("order", store.orders.selectedOrder)
        this.global.onOrderSaved()
        store.orders.linkDeliveryVisible = false
      } catch (e) {
      }
    }

  }
}
</script>
