import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/vue-select'

import App from './App.vue'
import router from './router'
import Client from './client/client'

Vue.config.productionTip = false
Vue.prototype.$api = new Client(process.env.VUE_APP_CLIENT_BASE_URL, process.env.VUE_APP_CLIENT_WS_URL)

let v = new Vue({
  el: '#app',
  router,
  render: h => h(App),
})

v.$api.setVue(v)
