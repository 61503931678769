<template>
  <b-modal id="change-delivery" v-model="global.cancelAutoDeliveryVisible" title="Доставка" @ok="onSubmit">
    <b-container>
      <b-row>
        <a class="m-2" v-if="linkAvailable" :href="getDeliveryLink()">Перейти в кабинет Яндекса</a>
      </b-row>
      <b-row><b-button class="m-2" v-if="!isManual" @click="setManual">Отменить доставку</b-button></b-row>
      <b-row><b-button class="m-2" v-if="isManual" @click="setAuto">Автоматически назначить доставку?</b-button></b-row>
      <b-row>
        <div v-if="requestFailed" class="m-2">
          <p>Возникла ошибка при оформлении доставки:</p>
          <p>{{ global.selectedOrder.deliveryClaimMessage }}</p>
        </div>
      </b-row>
    </b-container>
  </b-modal>
</template>


<script>

import {store} from "@/store"

export default {

  data() {
    return {
      global: store.orders,
    }
  },
  computed: {
    linkAvailable() {
      return this.global.selectedOrder.deliveryClaimId !== undefined
    },
    requestFailed() {
      return [16, 17, 18].includes(this.global.selectedOrder.deliveryClaimStatus)
    },
    isManual() {
      return [19].includes(this.global.selectedOrder.deliveryClaimStatus)
    }
  },

  methods: {
    getDeliveryLink() {
      return 'https://dostavka.yandex.ru/account/cargo?order=' + this.global.selectedOrder.deliveryClaimId;
    },

    async save() {
      await this.$api.post("order", store.orders.selectedOrder)
      this.global.onOrderSaved()
    },

    async setManual() {
      store.orders.selectedOrder.deliveryClaimStatus = 19
      store.orders.cancelAutoDeliveryVisible = false
      // await this.$api.postParams("delivery/cancel_claim", {claimId: this.global.selectedOrder.claimId})
      await this.save()
    },

    async setAuto() {
      store.orders.selectedOrder.deliveryClaimStatus = 10
      store.orders.cancelAutoDeliveryVisible = false
      await this.save()
    },

    async onSubmit(event) {
      event.preventDefault()
      store.orders.cancelAutoDeliveryVisible = false
    }
  },

}
</script>
