<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="auto" class="mr-auto pl-1">
          <h2 class="mb-0">Товары</h2>
        </b-col>
        <b-col cols="auto" class="pl-0 mt-1 pr-0">
          <b-button-toolbar>
            <b-button-group class="mx-1">
              <b-button :disabled="importStarted" @click="importStart">Запустить импорт</b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-1">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Искать" autofocus debounce="250" @update="onFilter"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-container>

    <b-table responsive hover small striped :items="items" :fields="fields">
      <template #head(purchasePrice)="data">
        <span class="text-nowrap">{{ data.label }}</span>
      </template>

      <template #cell(name)="data">
        <p class="mb-0 text-nowrap">
          <b-avatar variant="info" :src="data.item.photos"></b-avatar>
          &nbsp;
          <b-link @click="edit(data.item)">{{ data.value }}</b-link>
        </p>
      </template>
      <template #cell(price)="data">
        {{ data.value}}&nbsp;<del class="text-secondary font-weight-light" v-if="data.item.oldPrice > 0">{{ data.item.oldPrice }}</del>
      </template>
      <template #cell(hidden)="data">
        <b-form-checkbox :checked="data.value" @change="() => onChange(data)"/>&nbsp;
      </template>
    </b-table>

    <AddProduct v-on:saved="fetchData()"/>
  </div>
</template>

<script>
import AddProduct from "./AddProduct"
import {store} from "@/store"

export default {
  components: {AddProduct},
  data() {
    return {
      filter: "",
      importStarted: false,
      fields: [
        { label: 'Название', key: 'name', sortable: true },
        { label: 'Цена', key: 'price', sortable: true },
        { label: 'Цена закупки', key: 'purchasePrice', sortable: true },
        { label: 'Скрыт из поиска', key: 'hidden', sortable: false },
      ],
      items: [],
      global: store.products
    }
  },

  created() {
    // fetch on init
    this.updateImportStatus()
    this.fetchData()
    this.timer = setInterval(this.updateImportStatus, 1000)
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    async fetchData() {
      this.items = await this.$api.get("products/search", {filter: this.filter})
    },

    async onChange(data) {
      console.log(data)
      data.item.hidden = !data.item.hidden
      let newProduct = await this.$api.post("products", data.item)
      this.$emit('saved', newProduct)
    },

    onFilter() {
      this.fetchData()
    },

    async updateImportStatus() {
      let _importStarted = this.importStarted;
      this.importStarted = await this.$api.get("products/importStatus")

      if (_importStarted && !this.importStarted) {
        console.debug("Imported finished, reload")
        this.fetchData()
      }
    },

    async importStart() {
      let res = await this.$api.post("products/import");
      if (!res) {
        this.importStarted = true;
        this.$bvToast.toast(`Импорт уже запущен`, {
          title: 'Импорт',
          autoHideDelay: 2000,
          appendToast: true,
          solid: true
        })
      }
    },

    add() {
      this.global.selectedProduct = {}
      this.global.addProductVisible = true
    },

    edit(item) {
      this.global.selectedProduct = item;
      this.global.addProductVisible = true
    },

    // async remove(product) {
    //   let res = await this.$bvModal.msgBoxConfirm(`Удалить ${product.name}?`)
    //   if (res) {
    //     await this.$api.del("products", product.id)
    //     this.fetchData()
    //   }
    // }
  }
}
</script>


