import ReconnectingWebSocket from 'reconnecting-websocket';

export default class Client {
    constructor(baseUrl, wsUrl) {
        this.baseUrl = baseUrl
        this.wsUrl = wsUrl
        this.wsListeners = []
        this.promiseToWait = null
    }

    setVue(c) {
        this.vue = c
    }

    download(url, p) {
        window.open(this.baseUrl + url + "?" + this._objectToParamsString(p), "_blank")
    }

    async get(url, p) {
        return this._fetch("GET", url + "?" + this._objectToParamsString(p))
    }

    async post(url, p) {
        return this.postJson(url, p)
    }

    async postJson(url, p) {
        return this._fetch("POST", url, 'application/json', JSON.stringify(p, this._replacer))
    }

    async postParams(url, p) {
        return this._fetch("POST", url, 'application/x-www-form-urlencoded', this._objectToParamsString(p))
    }

    async del(url, p) {
        return this._fetch("DELETE", url + "/" + p)
    }

    loadFlorists(onLoad) {
        // TODO: call server only ones, than apply cached values
        this.get("user", {role: "FLORIST"}).then(onLoad)
    }

    async connectWebSocket() {
        if (process.env.NODE_ENV === "development") {
            const cookies = await this.get("dev/cookies")
            cookies.forEach(c => {
                document.cookie = c.name + "=" + c.value
            })
        }

        this.ws = new ReconnectingWebSocket(this.wsUrl, null, {debug: process.env.NODE_ENV === "development"})
        this.ws.onmessage = e => {
            var msg = JSON.parse(e.data);
            this.wsListeners.forEach(l => l(msg))
        }
    }

    closeWebSocket() {
        this.ws.close()
    }

    addWebSocketListener(l) {
        this.wsListeners.push(l)
    }

    removeWebSocketListener(l) {
        const index = this.wsListeners.indexOf(l);
        if (index > -1) {
            this.wsListeners.splice(index, 1);
        }
    }

    _objectToParamsString(p) {
        if (!p) return ""

        return Object.keys(p).reduce((s, k) => s + (s === "" ? "" : "&") + k + "=" + encodeURIComponent(p[k]), "")
    }

    async _fetch(method, url, contentType, body) {
        if (this.promiseToWait) {
            await this.promiseToWait
        }

        let error = null
        let res = null
        try {
            let params = {
                method: method,
                credentials: 'include'
            }

            if (contentType) {
                params['headers'] = {'Content-Type': contentType}
            }

            if (body) {
                params['body'] = body
            }

            res = await fetch(this.baseUrl + url, params)

            if (res.ok) {
                if (res.headers.get("Content-Type") === 'application/json') {
                    return await res.json()
                } else {
                    return null
                }
            } else if (res.status === 403) {
                this.openLogin()
                error = "Надо залогиниться!"
            } else if (res.status === 401) {
                error = "Доступно только администратору"
            } else {
                error = await res.text()
            }
        } catch (e) {
            this._showError(e.message);
            throw e
        }

        this._showError(error);
        throw error
    }

    _showError(text) {
        this.vue.$bvToast.toast(text, {
            title: 'Критическая ошибка',
            'no-auto-hide': true,
            'is-status': true,
            appendToast: true,
            solid: true
        })
    }

    openLogin() {
        window.location.replace('/login')
        //this.vue.$router.push('/login');
    }

    logout() {
        this.post("user/logout", {}).then(r => {
            window.location.replace('/login')
            //this.vue.$router.push('/login');
        })
    }

    // convert date to millis
    _replacer(key, value) {
        // value always is string, so refere to original object value
        // this is refered to original obkject being jsonifing
        if (this[key] instanceof Date) {
            return this[key].getTime();
        }
        return value;
    }
}
