<template>
  <div>
    <span v-if="customer.contactType === 1">
      <a :href="'api/customers/redirect/c2d?phone=' + customer.contact" target="_blank">{{ customer.contact }}</a>
    </span>
    <span v-if="customer.contactType === 2">
      <a :href="'tel:' + customer.contact">{{ customer.contact }}</a>
    </span>
    <span v-if="customer.contactType === 3">
      <a :href="'https://t.me/' + customer.contact" target="_blank">{{ customer.contact }}</a>
    </span>
    <span v-if="customer.contactType === 4">
      <a :href="'https://vk.com/gim22405667?q=' + customer.contact" target="_blank">{{ customer.contact }}</a>
    </span>
    <span v-if="customer.contactType === 5">
      <a :href="'https://instagram.com/' + customer.contact" target="_blank">{{ customer.contact }}</a>
    </span>
    {{ contactTypeDescr }}
  </div>
</template>

<script>
import {consts} from "@/consts";

export default {
  props: {
    customer: Object
  },

  computed: {
    contactTypeDescr() {
      return this.customer && this.customer.contactType ? consts.customers.contactTypes[this.customer.contactType].name : ""
    }
  },

  methods: {
    prepareWAContact(phone) {
      // remove all not digital chars
      return phone.replaceAll(/[^\d]/g, "")
    }
  }
}
</script>
