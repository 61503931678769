<template>
  <b-modal id="change-state" v-model="global.orders.changeStateVisible" title="Состояние заказа"
           @show="onShow" @ok="onSubmit">
    <b-form-group>
      <b-form-radio-group v-model="newState" :options="states" button-variant="outline-primary" buttons stacked>
      </b-form-radio-group>
    </b-form-group>
  </b-modal>
</template>

<script>

import {store} from "@/store"
import {consts} from "@/consts";

export default {

  data() {
    return {
      newState: 0,
      newPrepaid: 0,
      global: store,
      states: consts.orders.statesWithoutImportedArr,
    }
  },

  methods: {
    onShow() {
      this.newState = store.orders.selectedOrder.state
      this.newPrepaid = store.orders.selectedOrder.prepaid
    },

    async onSubmit(event) {
      event.preventDefault()
      store.orders.selectedOrder.state = this.newState
      store.orders.selectedOrder.prepaid = this.newPrepaid
      try {
        await this.$api.post("order", store.orders.selectedOrder)
        store.orders.changeStateVisible = false
        this.global.onOrderSaved()
      } catch (e) {
      }
      if (this.newState === 3 && store.orders.selectedOrder.customer.type === 0 ) {
        store.customers.showChangeCustomerType(store.orders.selectedOrder.customer)
      }
    }

  }
}
</script>
